import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, type MenuProps } from 'antd';
import { signOut } from 'firebase/auth';
import { auth } from '@/lib/firebase';
import { removeToken } from '@/store/reducer/user';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import logoIcon from "@/assets/user_logo.png";
// import memberIcon from "@/assets/member.png";
import homeIcon from "@/assets/home.png";
// import assetsIcon from "@/assets/assets.png";
// import collectionsIcon from "@/assets/collections.png";
// import marketPlaceIcon from "@/assets/market-place.png";
// import uploadIcon from "@/assets/upload.png";
// import ordersIcon from "@/assets/orders.png";
import scenesIcon from "@/assets/scenes.png";
import closeIcon from "@/assets/close.png";
import openIcon from "@/assets/open.png";
import feedbackIcon from "@/assets/feedback.png";
import helpIcon from "@/assets/help.png";

import "./Navbar.css";
const items: MenuProps['items'] = [
    {
        key: "userAgreement",
        label: <button>User Agreement</button>
    },
    {
        key: 'signOut',
        label: (
            <button>signOut</button>
        ),
    },
]


const Navbar: React.FC = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const photoURL = localStorage.getItem("photoURL")!;
    const dispatch = useDispatch();
    var navigator = useNavigate();

    const toggleNavbar = () => {
        setIsCollapsed(prevState => !prevState);
    };

    const handleLogout: MenuProps['onClick'] = (e) => {
        if (e.key === 'signOut') {
            signOut(auth).then(() => {
                dispatch(removeToken());
                localStorage.removeItem("token_key");
                console.log("Signed out successfully")
            }).catch((error) => {
                console.log(error);
            });
        }
        if (e.key === 'userAgreement') {
            navigator("/agreement");
        }
    };

    return (
        <nav className={`navbar flex flex-col p-6 text-white text-base h-screen duration-300 ${isCollapsed ? 'w-20' : 'w-[14rem]'}`}>
            <section className='flex items-center mb-10'>
                {/* <img src={localStorage.getItem("photoURL") || ""} alt="user-logo" width={24} height={24} className='mr-1 rounded-full'/> */}
                <Dropdown menu={{ items, onClick: handleLogout, }} placement="bottomRight">
                    <img src={photoURL} alt="" width={24} height={24} className=" rounded-full cursor-pointer mr-1" />
                </Dropdown>

                {!isCollapsed && <div className='flex items-center user'>
                    <span>{localStorage.getItem("username") || "Cybever_admin"}</span>
                    {/* <img src={memberIcon} alt="member" width={12} height={12} className='ml-2' /> */}
                </div>
                }
            </section>
            <section className='flex-1 flex flex-col justify-between'>
                <ul className="navbar-item-container">
                    <li>
                        <Link to="/" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={homeIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Home</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/myscenes" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={scenesIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>My Scenes</span>}
                        </Link>
                    </li>
                    {/* <li className='line'></li>
                    <li>
                        <Link to="/marketplace" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={marketPlaceIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Marketplace</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/collections" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={collectionsIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Collections</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/assetslibrary" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={assetsIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Assets Library</span>}
                        </Link>
                    </li>
                    <li>
                        <Link to="/upload" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={uploadIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Upload & Review</span>}
                        </Link>
                    </li> */}
                </ul>
                <div>
                    {/* <p className='order'>
                        <Link to="/myorders" className="text-white opacity-70 hover:text-gray-300 flex">
                            <img src={ordersIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>My Orders</span>}
                        </Link>
                    </p> */}
                    <p className='order mb-5'>
                        <Link
                            to="https://www.canva.com/design/DAGUl59Bkck/MJxR96imAdq6oUef2OjKFg/view?utm_content=DAGUl59Bkck&utm_campaign=designshare&utm_medium=link&utm_source=editor"
                            target="_blank"
                            className="text-white opacity-70 hover:text-gray-300 flex"
                        >
                            <img src={helpIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Tutorial</span>}
                        </Link>
                    </p>
                    <p className='order'>
                        <Link
                            to="https://survey.zohopublic.com/zs/ORD4wl"
                            target="_blank"
                            className="text-white opacity-70 hover:text-gray-300 flex"
                        >
                            <img src={feedbackIcon} alt="" width={24} height={24} className=' mr-5' />
                            {!isCollapsed && <span>Feedback</span>}
                        </Link>
                    </p>
                    <button onClick={toggleNavbar} className='my-10'>
                        {isCollapsed && <img src={openIcon} alt="collapseIcon" width={24} height={24} />}
                        {!isCollapsed && <img src={closeIcon} className=' ml-32' alt="collapseIcon" width={24} height={24} />}
                    </button>
                </div>
            </section>
        </nav>
    );
};

export default Navbar;
